import React from 'react';
import {
  CurrentUser,
  GatewayNetworkInterfaceHumanReadable,
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import { getSensorsTableColumns } from './Sensors';
import {
  getTitle,
  getDetailFields,
  getSummaryFields,
  getSensorsOrActuatorsSummaryFields,
  transformDhcpStatusValue,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { ComponentHeader, InspectionComponent } from './InspectionComponent';
import { DeviceIcon } from '../Pdf/Icons';

type Props = {
  data: InspectionDataFieldsByKey;
  currentUser?: CurrentUser;
  isSuperHub?: boolean;
};

export const Hub = (props: Props) => {
  const { data, currentUser, isSuperHub } = props;

  if (!currentUser) return null;
  const hubFields = isSuperHub
    ? [
        {
          name: 'MCU Address (UUID)',
          key: 'UUID',
        },
        {
          name: 'Firmware Version',
          key: 'firmware_version',
        },
        {
          name: 'RF Pan ID',
          key: 'rf_pan_id',
        },
        {
          name: 'MQTT Broker',
          key: 'mqtt_broker',
        },
      ]
    : [
        {
          name: 'IP Address',
          key: 'ip',
        },
        {
          name: 'DHCP Status',
          key: 'ip_setting',
          transformValue: transformDhcpStatusValue,
        },
        {
          name: 'Default Gateway',
          key: 'default_gateway',
        },
        {
          name: 'MCU Address (UUID)',
          key: 'UUID',
        },
        {
          name: 'Subnet Mask',
          key: 'subnet_mask',
        },
        {
          name: 'Firmware Version',
          key: 'firmware_version',
        },
        {
          name: 'DNS 1',
          key: 'DNS_1',
        },
        {
          name: 'Connection Type',
          key: 'connection_type',
        },
        {
          name: 'DNS 2',
          key: 'DNS_2',
        },
        {
          name: 'Network Allowance',
          key: 'network_allowance',
          transformValue: value =>
            GatewayNetworkInterfaceHumanReadable[value] ||
            value ||
            global.NOT_AVAILABLE,
        },
        {
          name: '4G Carrier',
          key: 'network_operator',
        },
        {
          name: 'APN',
          key: 'apn',
        },
        {
          name: '4G RSSI',
          key: 'rssi',
          transformValue: value =>
            !isNaN(value) &&
            isDefined(value) &&
            (data.network_allowance as InspectionDataField)?.field !==
              'ETHERNET_ONLY'
              ? `${value} dB`
              : global.NOT_AVAILABLE,
        },
        {
          name: 'RF Pan ID',
          key: 'rf_pan_id',
        },
        {
          name: 'MQTT Broker',
          key: 'mqtt_broker',
        },
      ];
  const isWhitelistSensorNull = (uuid: string = '') => {
    const findings = (data.whitelist as InspectionDataFieldsByKey[]).find(
      key => (key.uuid as InspectionDataFieldsByKey).field === uuid
    );
    return (findings && findings.sensor) === null;
  };

  const sensorsColumns = getSensorsTableColumns({
    currentUser,
    isWhitelistSensorNull,
  });
  const subtitle = getTitle(data);
  const tableData = [
    data.sensors as InspectionDataFieldsByKey[],
    (data.whitelist as InspectionDataFieldsByKey[])
      ?.sort(item => {
        const finding = (item.uuid as InspectionDataFieldsByKey).field;
        return isWhitelistSensorNull(finding as string) ? -1 : 1;
      })
      .map(item =>
        item.sensor
          ? {
              ...(item.sensor as InspectionDataFieldsByKey[]),
            }
          : {
              UUID: item.uuid,
            }
      ) || [],
  ];

  const hubSummaryFields = getSummaryFields(data);
  const sensorsSummaryFields = getSensorsOrActuatorsSummaryFields(
    (data.sensors as InspectionDataFieldsByKey[]).concat(
      (data.whitelist as InspectionDataFieldsByKey[]) || []
    ),
    {
      statisticsData: {
        result: 'sensors',
        errors:
          ((data.errors_sensors as number) || 0) +
          ((data.errors_whitelist as number) || 0),
        warnings:
          ((data.warnings_sensors as number) || 0) +
          ((data.warnings_whitelist as number) || 0),
      },
    }
  );

  const inspectionComponentName = isSuperHub
    ? InspectionComponentName.EB_SUPER_HUB
    : InspectionComponentName.HUB;

  return (
    <>
      <InspectionComponent
        title={inspectionComponentName}
        titleIcon={<DeviceIcon type={inspectionComponentName} />}
        subtitle={subtitle}
        summaryFields={hubSummaryFields}
        detailFields={getDetailFields(
          hubFields,
          data,
          InspectionDetailLevel.ALL
        )}
        detailTableHeader={
          <ComponentHeader
            title={InspectionComponentName.SENSORS}
            summaryFields={sensorsSummaryFields}
          />
        }
        detailTable={[
          {
            title: 'Sensors',
            props: {
              columns: sensorsColumns,
              data: tableData[0],
              headerWrap: true,
            },
          },
          {
            title: 'Whitelist',
            props: {
              columns: sensorsColumns,
              data: tableData[1],
              headerWrap: true,
            },
          },
        ]}
        noTableDataMessage="No Sensors Configured"
      />
    </>
  );
};
